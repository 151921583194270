import { Component, OnInit } from '@angular/core';
import { ContatoService } from '../shared/contato/contato.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Contato } from '../shared/contato/contato';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {

  contato: Contato;
  key: string;
  invalid: boolean = false;

  constructor(private contatoService: ContatoService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.key = params.get('key');
        this.contatoService.get(this.key).subscribe(c => {
          c.validado = true;
          this.contatoService.update(c, this.key);
          this.contato = c;
        },
        err => {
          console.error("Erro ao tentar localizar chave: ", err);
          this.invalid = true;
          
        })
      });
  }
}
