import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ContatoService } from '../shared/contato/contato.service';
import { map, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Contato } from '../shared/contato/contato';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  loginForm: FormGroup;
  loading: boolean = false;
  lista$: Observable<Contato[]>;
  
  constructor(private formBuilder: FormBuilder,
              private contatoService: ContatoService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      login: new FormControl('', {
        validators: [ Validators.required, Validators.minLength(3) ],
        updateOn: 'blur'
      }),
      senha: new FormControl('', {
        validators: Validators.required,
        updateOn: 'blur'
      })
    });
  }

  get login() {
    return this.loginForm.get('login');
  }

  get senha() {
    return this.loginForm.get('senha');
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.loading = true;

      if (this.login.value != 'Marcio' && this.senha.value != 'm@rci007') {
        this.loading = false;
        this.login.markAsDirty();
      } else {
        this.lista$ = this.contatoService.getAll();
        this.loading = false;
      }
    }
  }

  filterRegs(list: Observable<Contato[]>) {
    if (list)
      return list.pipe(map((ls: Contato[]) => ls.filter(x => x.validado)));
  }
}
