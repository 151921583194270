import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  nome: string;
  email: string;

  constructor() { }

  ngOnInit() {
    this.nome = localStorage.getItem('nome');
    this.email = localStorage.getItem('email');
    let match = /(.+)(.{3}@.+)$/.exec(this.email);
    let repl = match[1].replace(/./g, '*');
    this.email = repl + match[2];
    //localStorage.clear();
  }

}
