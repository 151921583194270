import { Injectable } from '@angular/core';
import { Contato } from './contato';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContatoService {

  constructor(private db: AngularFireDatabase) { }

  get(key: string): Observable<Contato> {
    return this.db.object('contato/' + key).valueChanges().pipe(
      map((c: any) => {
        let result = new Contato();
        result.nome = c.nome;
        result.sobrenome = c.sobrenome;
        result.dataNascimento = c.dataNascimento;
        result.sexo = c.sexo;
        result.email = c.email;

        return result;
      })
    );
  }

  getAll(): Observable<Contato[]> {
    return this.db.list<Contato>('contato').valueChanges();
  }

  insert(contato: Contato) {
    return new Promise((res, rej) => {
      this.db.list('contato').push(contato)
      .then((result: any) => {
        console.log("result: ", result);
        res(result.key);
      })
      .catch(err => { rej(err); })
    });
  }

  update(contato: Contato, key: string) {
    this.db.list('contato').update(key, contato)
      .catch(err => { console.error("Erro em ContatoService.update: ", err); });
  }
}
