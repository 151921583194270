import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AngularFireDatabase } from '@angular/fire/database';
import { ConfirmComponent } from './confirm/confirm.component';
import { DownloadComponent } from './download/download.component';
import { ListComponent } from './list/list.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NotFoundComponent,
    ConfirmComponent,
    DownloadComponent,
    ListComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    RouterModule.forRoot([
      { 
        path: '', 
        component: LandingComponent 
      },
      { 
        path: 'contato', 
        component: LandingComponent 
      },
      { 
        path: 'confirmacao', 
        component: ConfirmComponent 
      },
      { 
        path: 'lista', 
        component: ListComponent 
      },
      { 
        path: 'download/:key', 
        component: DownloadComponent 
      },
      { 
        path: '**', 
        component: NotFoundComponent 
      }
    ]),
    NgbModule.forRoot(),
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireFunctionsModule
  ],
  providers: [AngularFireDatabase],
  bootstrap: [AppComponent]
})
export class AppModule { }
