// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: { 
    apiKey: "AIzaSyD1a3mZ7wa3ZKc7uiQnhasFJZzwYwT5x3c",
    authDomain: "mm-poker.firebaseapp.com",
    databaseURL: "https://mm-poker.firebaseio.com",
    projectId: "mm-poker",
    storageBucket: "",
    messagingSenderId: "1038068151797",
    appId: "1:1038068151797:web:0ed6a77cda9306b4"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
