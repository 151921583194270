import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2'
import { Contato } from '../shared/contato/contato';
import { ContatoService } from '../shared/contato/contato.service';
import { EmailService } from '../shared/email/email.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  profileForm: FormGroup;
  loading: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private contatoService: ContatoService,
              private emailService: EmailService) { }

  ngOnInit() {
    this.profileForm = this.formBuilder.group({
      nome: new FormControl('', {
        validators: [ Validators.required, Validators.minLength(3) ],
        updateOn: 'blur'
      }),
      sobrenome: new FormControl('', {
        validators: Validators.required,
        updateOn: 'blur'
      }),
      dtNasc: new FormControl('', {
        validators: Validators.required,
        updateOn: 'blur'
      }),
      sexo: new FormControl('', {
        validators: Validators.required,
        updateOn: 'blur'
      }),
      email: new FormControl('', {
        validators: [ Validators.required, Validators.email ],
        updateOn: 'blur'
      }),
    });
  }

  get nome() {
    return this.profileForm.get('nome');
  }

  get sobrenome() {
    return this.profileForm.get('sobrenome');
  }

  get dtNasc() {
    return this.profileForm.get('dtNasc');
  }

  get sexo() {
    return this.profileForm.get('sexo');
  }

  get email() {
    return this.profileForm.get('email');
  }

  onSubmit() {
    if (this.profileForm.valid) {
      this.loading = true;

      let contato = new Contato();
      contato.nome = this.nome.value;
      contato.sobrenome = this.sobrenome.value;
      contato.dataNascimento = this.dtNasc.value;
      contato.sexo = this.sexo.value;
      contato.email = this.email.value;

      this.contatoService.insert(contato).then((key: string)  => {
        // Enviar email usando a key
        this.emailService.sendEmail(contato.nome, contato.email, key).subscribe(resp => {
          console.log(resp);
          Swal.fire("DOWNLOAD LIBERADO!", 
                    "Acesse seu email para baixar seu e-book GRATUITAMENTE!", 
                    "success").then(() => {
                      localStorage.setItem('nome', contato.nome);
                      localStorage.setItem('email', contato.email);
                      this.router.navigate(['/confirmacao']);
                    });
        },
        error => {
          console.error(error);
          Swal.fire("PROBLEMA", "Erro ao enviar email: " + error, "error")
        });
      });
    }
  }

}
